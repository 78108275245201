import React,{Component} from 'react';
import { Row, Col, Spin} from 'antd';
import {customComponent} from '../../../custom';
import * as Public from 'publicComponent';
import * as Gen from '../../../component';
import { Icon } from 'libs';
import { keyTrans } from './logConst';
let {
    Table
} = {...Public,...Gen,...customComponent};
const {ListData,ColumnHeader} = Table;
const integrityTrans = {
    'pass': 'com.CheckPassed',
    'fail': 'com.DynamicCodeError3',
    'miss': 'com.CheckCodeLoss'
};
class LogBody extends Component {
    state = {
        visible: false,
        currentItem:{}
    }
    drawerHandlerChange = (status) => {
        const {visible} = this.state;
        this.setState({
            visible: status === undefined ? !visible : status
        });
    }

    handlerClick = (record) => {
        this.setState({
            currentItem:record
        },() => {
            this.drawerHandlerChange(true);
        });
    }
    componentWillUnmount () {
        this.drawerHandlerChange(false);
    }
    renderText = (detail) => {
        let {$t} = this.props;
        // let text = '';
        var result = Object.keys(detail).map((key) => {
            if (key === 'FileName') {
                const listItems = detail[key].map((number,id) =>
                    <li key={id}>{number}</li>
                );
                return (
                    <Row key={key}>
                        <Col spa={4}>{$t('com.FileName')}:</Col>
                        <Col span={24} style={{maxHeight: 420, overflowY: 'auto'}}><ul>{listItems}</ul></Col>
                    </Row>
                );
            }
            // 一些应用端没有翻译的英文类文案需要web端翻译
            let name = keyTrans[detail[key]] ? $t(keyTrans[detail[key]]) : detail[key];
            return (<p key={key}> {`${key}${': '}${name}`}</p>);
        });
        return result;
        // console.log(text);
        // if (text) {
        //     return text.split('\n').filter(item => item).map(item => <p key={item}>{item}</p>);
        // }
    }
    render () {
        let {items,$t,offset,pagination, loading, SupportFileIntegrityCheck} = this.props;
        let column = [
            {title:$t('com.Numbers'),key:'key'},
            {title:$t('com.Time'),key:'Time'},
            {title:$t('com.Username'),key:'User'},
            {title:$t('com.Type'),key:'Type'},
            {title:$t('com.DetailInfo'),key:'check'}
        ];
        let {currentItem} = this.state;
        let detail = currentItem.Detail || [];
        if (typeof detail === 'object') {
            // let arr = [];
            // for (let key in detail) {
            //     let obj = {};
            //     obj[key] = detail[key];
            //     arr.push(obj);
            // }
            // detail = [...arr];
            // console.log(detail);
        }

        let listData = new ListData(items).get();
        // 检查是否有完整性校验的字段 - Integrity
        if (SupportFileIntegrityCheck && listData.some(item => item.Integrity)) {
            column.push({
                title: $t('sys.FileIntegrityCheck'),
                key: 'Integrity',
                render: (text, record) => $t(integrityTrans[record.Integrity])
            });
        }

        let columns = new ColumnHeader(column);
        columns.addOtherColumn('check',(text,record) => (
            <Icon onClick={() => { this.handlerClick(record); }} type="desc"></Icon>
        ));

        columns.addOtherColumn('key',(text,record) => (`${offset + record.key + 1}`));

        let columnsData = columns.get();
        let drawer = {
            title:$t('com.Detail'),
            width:'296px',
            visible: this.state.visible,
            getContainer:false,
            onClose:() => this.drawerHandlerChange(false),
            render: (
                <DrawerComponent
                    currentItem = {currentItem}
                    detail = {detail}
                    $t = {$t}
                    renderText = {this.renderText}
                />
            )
        };
        return (
            <section className="_logBody_">
                <Spin spinning={!!loading}>
                    <Table
                        onRow={record => {
                            return {
                                onClick: event => {this.handlerClick(record);}, // 点击行
                            };
                        }}
                        dataSource={listData}
                        columns={columnsData}
                        pagination = {pagination}
                        drawer = {drawer}
                    />
                </Spin>
            </section>
        );
    }
}

class DrawerComponent extends Component {
    constructor (props) {
        super(props);
    }
    render () {
        let {currentItem, detail, renderText, $t} = this.props;

        return (<div style={{paddingLeft:'10px'}}>
            <Row className="row">
                <Col span={8}>
                    {$t('com.Time')}
                </Col>
                <Col span={14} offset={2}>
                    {currentItem.Time}
                </Col>
            </Row>
            <Row className="row">
                <Col span={8}>
                    {$t('com.Type')}
                </Col>
                <Col span={14} offset={2}>
                    {currentItem.Type}
                </Col>
            </Row>
            <Row className="row">
                <Col span={8}>
                    <p>{$t('sys.LogContent')}</p>
                </Col>
                <Col span={14} offset={2}>
                    <div className='logTextDeatil'>
                        {
                            renderText(detail)
                        }
                    </div>
                </Col>
            </Row>
        </div>);
    }
}

export {LogBody};